var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown tw-h-7 2xl:tw-h-9 tw-rounded-lg 2xl:tw-rounded-xl tw-text-sm tw-tw-text-base",class:{
        'dropdown--menu': _vm.menu,
        'dropdown--searching': _vm.isSearchOffset,
        'dropdown--reposition': _vm.reposition,
    },style:({
        // ...cssVars,
        width: _vm.width || 'auto',
        maxWidth: _vm.maxWidth || 'unset',
        minWidth: _vm.minWidth || 'unset',
    })},[_c('VueMultiselect',_vm._b({ref:"multiselect",class:{'open-top': _vm.openDirection === 'top'},attrs:{"options":_vm.options,"multiple":_vm.multiple,"searchable":_vm.searchable,"close-on-select":!_vm.multiple,"placeholder":_vm.placeholder,"allow-empty":_vm.allowEmpty,"disabled":_vm.isDisabled,"loading":_vm.loading},on:{"select":function($event){return _vm.$emit('select', $event)},"search-change":function($event){return _vm.$emit('search', $event)},"input":_vm.changeValue,"open":_vm.opened,"close":_vm.closed},scopedSlots:_vm._u([{key:"caret",fn:function(ref){
    var toggle = ref.toggle;
return [_c('div',{staticClass:"caret caret--rotable tw-right-2 2xl:tw-right-1.5",class:_vm.isDisabled ? 'tw-hidden' : '',on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle()}}},[_c('img',{staticClass:"tw-mr-2 tw-w-2 tw-h-2",attrs:{"src":require("@/assets/icons/dropdown.svg")}})])]}},{key:"singleLabel",fn:function(props){return [_c('div',{staticClass:"custom-label custom-label--singleLabel"},[_vm._t("before"),_c('span',{staticClass:"custom-label__text"},[_vm._v(" "+_vm._s(_vm.getLabel(props.option))+" ")]),_vm._t("after")],2)]}},{key:"option",fn:function(props){return [_vm._t("option",[_c('div',{staticClass:"custom-label custom-label--option",attrs:{"title":_vm.getLabel(props.option)}},[(_vm.multiple && _vm.showCheckBox)?_c('input',{staticClass:"focus:ring-blue-500 tw-h-4 tw-w-4 tw-text-blue-600 tw-border-gray-300 tw-rounded-md tw-pointer-events-none",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isOptionChecked(props.option)}}):_vm._e(),(props.option.icon)?_c(props.option.icon,{tag:"component",staticClass:"custom-label__icon"}):_vm._e(),_c('span',{staticClass:"custom-label__text",style:({color: _vm.optionTextColor})},[_vm._v(" "+_vm._s(_vm.getLabel(props.option))+" ")])],1)],{"option":props.option})]}},{key:"tag",fn:function(props){return [_c('div',{staticClass:"custom-tag tw-h-5 tw-text-xs 2xl:tw-h-6 2xl:tw-text-sm"},[_c('span',{staticClass:"tw-truncate custom-tag-label"},[_vm._v(" "+_vm._s(_vm.getLabel(props.option))+" ")]),_c('button',{staticClass:"custom-tag__icon",attrs:{"title":"Remove","type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return props.remove(props.option)}}},[_c('img',{staticClass:"tw-mr-2 tw-w-2 tw-h-2",attrs:{"src":require("@/assets/icons/cross.svg")}})])])]}},{key:"clearAll",fn:function(ref){
    var clearAll = ref.clearAll;
return (_vm.multiple && _vm.value && _vm.value.length)?[_c('div',{staticClass:"custom-label custom-label--clear-all tw-py-2 tw-px-3 tw-block"},[_c('div',{staticClass:"tw-text-sm tw-font-normal tw-text-primary tw-cursor-pointer",on:{"click":clearAll}},[_vm._v("Clear selected items")])])]:undefined}}],null,true),model:{value:(_vm.calculatedValue),callback:function ($$v) {_vm.calculatedValue=$$v},expression:"calculatedValue"}},'VueMultiselect',Object.assign({}, _vm.computedConfig, _vm.$attrs),false),[_c('template',{slot:"placeholder"},[_c('div',{staticClass:"custom-label custom-label--placeholder"},[_vm._t("before"),_c('div',{staticClass:"custom-label__text",class:{opacity: !_vm.menu}},[_vm._v(_vm._s(_vm.placeholder))]),_vm._t("after")],2)]),(_vm.multiple && _vm.options && _vm.options.length)?_c('template',{slot:"beforeList"},[_c('div',{staticClass:"custom-label multiselect__before-list tw-flex tw-items-center tw-gap-1 tw-py-2 tw-px-3"},[_c('div',{staticClass:"tw-font-normal tw-text-primary tw-text-base",on:{"click":_vm.toggleAll}},[_vm._v(" "+_vm._s(_vm.calculatedValue.length === _vm.options.length ? " Clear all" : "Select all")+" ")])])]):_vm._e(),(_vm.loading)?_c('template',{slot:"noResult"},[_vm._v(" Loading... ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }