<template>
    <div class="field-table tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-full tw-text-base-content">
        <vue-good-table ref="table" mode="remote" :isLoading="loading" :columns="columns" :rows="rows" :pagination-options="paginationOptions" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :key="key">
            <template slot="table-actions">
                <button
                    v-if="Object.values(filters).length"
                    @click="clearFilters"
                    class="tw-bg-transparent tw-border tw-border-solid tw-rounded-2xl tw-px-2 tw-py-1 tw-mr-2 tw-cursor-pointer 
                    tw-border-brand tw-text-brand hover:tw-bg-brand hover:tw-text-white"
                >
                    RESET FILTER
                </button>
                <span v-else></span>
            </template>

            <template #column-filter="{ column }">
                <neo-datepicker v-if="column.field_type === 'date' && column.filterOptions && column.filterOptions.enabled" :range="true" v-model="filters[column.field]" :open-date="new Date()" placeholder="Select Date" format="DD-MMM-YYYY" class="tw-h-10 tw-rounded tw-w-full tw-overflow-hidden" ref="datePicker1" @input="updateColumnFilters()" @clear="clearDateRange(column)"> </neo-datepicker>

                <vue-multiselect ref="setFilterRef" v-else-if="column.filterOptions && column.filterOptions.enabled" placeholder="Select" v-model="filters[column.field]" :options="getFilterOptions(column.filterOptions.key)" @input="updateColumnFilters()" :multiple="false" :checkboxes="false" :limit="1" :close-on-select="true" :clear-on-select="true" :preserve-search="true" :searchable="true" :show-labels="false" class="tw-h-8 tw-w-full"> </vue-multiselect>
            </template>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field_type === 'date'">
                    <span v-if="props.row[props.column.field]">
                        {{ formatDateFromISO(props.row[props.column.field], "dd MMM yyyy") }}
                    </span>
                    <span v-else> - </span>
                </div>
                <span v-else> {{ props.row[props.column.field] }} </span>
            </template>
            <div slot="loadingContent" class="tw-text-gray-500 loader-position">
                <div class="tw-bg-blue-100 tw-p-8 tw-rounded-md tw-shadow-lg tw-flex tw-flex-col tw-items-center tw-max-w-3xl tw-break-all">
                    <svg class="tw-animate-spin tw-h-8 tw-w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="tw-opacity-25" cx="12" cy="12" r="10" stroke="var(--brand-color)" stroke-width="4"></circle>
                        <path class="tw-opacity-75" fill="var(--brand-color)" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <div class="tw-text-base tw-mt-4">Please wait loading ...</div>
                </div>
            </div>

            <div v-if="loading" slot="emptystate" class="tw-bg-white tw-flex tw-justify-center tw-p-4 tw-items-center" style="height: 250px"></div>
            <div v-if="!loading" slot="emptystate" class="tw-bg-white tw-flex tw-justify-center tw-p-4 tw-items-center" style="height: 250px">
                <div class="tw-flex tw-rounded-lg w-p-4 tw-mb-4 tw-text-sm tw-text-gray-500" role="alert">
                    <div><span class="font-medium"></span> No data for table</div>
                </div>
            </div>
        </vue-good-table>
    </div>
</template>
<script>
import Modal from "@/components/modal-content";
import dropdown from "@/components/dropdown-base";
import NeoInput from "@/components/input";
import Loader from "@/components/loader";
const VueMultiselect = () => import("@/components/vue-multiselect");
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {DateTime} from "luxon";
import {getCreditUsage, getUserFullName} from "../services";
import {mapGetters} from "vuex";

export default {
    name: "credit-usage",
    title: "Credit Usage",
    components: {
        Modal,
        dropdown,
        NeoInput,
        Loader,
        "neo-datepicker": DatePicker,
        VueMultiselect,
    },
    data() {
        return {
            key: 0,
            loading: false,
            columns: [
                {
                    label: "Pack Name",
                    field: "name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credit_pack_names",
                    },
                },
                {
                    label: "Unique Id",
                    field: "display_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credit_pack_display_names",
                    },
                },
                {
                    label: "User",
                    field: "user_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "user_names",
                    },
                },
                {
                    label: "Date",
                    field: "updated_at",
                    field_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                },
                {
                    label: "Case Id",
                    field: "case_id",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "case_ids",
                    },
                },
                {
                    label: "Product Name",
                    field: "product_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "product_names",
                    },
                },
                {
                    label: "Feature",
                    field: "credit_source_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "feature_names",
                    },
                },
                {
                    label: "Credits Used",
                    field: "credits_used",
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                        key: "credits_used",
                    },
                },
            ],
            rows: [],
            totalRecords: 0,
            filters: {},
            pagination: {
                limit: 10,
                page: 1,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage} of ${params.totalRecords}`,
                perPageDropdown: [10, 20, 30, 40, 50],
                dropdownAllowAll: false,
            },
            userLists: [],
            search: "",
        };
    },
    async mounted() {
        this.clearFilters();
        // await this.fetchData();
    },
    computed: {
        ...mapGetters(["getAccountId", "getIsUserAdmin", "getUserAuthDetails", "getFiltersData"]),
        filteredUserLists() {
            return this.userLists.filter((el) => el.user_name.toLowerCase().includes(this.search.toLowerCase()));
        },
    },
    methods: {
        disabledRange(date) {
            return date > new Date();
        },

        async clearDateRange(column) {
            this.filters[column.field] = [];
            // await this.fetchData();
        },

        async fetchData() {
            this.loading = true;
            this.rows = [];
            let qParams = {
                ...this.pagination,
            };

            const dateFields = this.columns.filter((col) => col.field_type === "date")?.map((col) => col.field);

            for (const filter in this.filters) {
                if (dateFields.includes(filter)) {
                    this.filters[filter] = this.filters[filter].filter((el) => el);
                    if (this.filters[filter]?.length) {
                        let key = filter;
                        if (filter === "updated_at") key = "date";

                        const start = new Date(this.filters[filter][0]);
                        const end = new Date(this.filters[filter][1]);
                        qParams = {
                            ...qParams,
                            [`${key}_from`]:
                                start.getFullYear() +
                                "-" +
                                (start.getMonth() + 1).toString().padStart(2, "0") +
                                "-" +
                                start
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0"),
                            [`${key}_to`]:
                                end.getFullYear() +
                                "-" +
                                (end.getMonth() + 1).toString().padStart(2, "0") +
                                "-" +
                                end
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0"),
                        };
                    }
                } else if (filter === "name")
                    qParams = {
                        ...qParams,
                        ["pack_name"]: this.filters[filter],
                    };
                else
                    qParams = {
                        ...qParams,
                        [filter]: this.filters[filter],
                    };
            }
            if (this.getAccountId)
                qParams = {
                    ...qParams,
                    tenant_id: this.getAccountId,
                };

            if (!this.getIsUserAdmin)
                qParams = {
                    ...qParams,
                    ...{user_id: this.getUserAuthDetails.userId},
                };
            const {data} = await getCreditUsage(qParams);
            this.loading = false;
            this.rows = data.data.map((el) => ({
                ...el,
                ...{
                    alloted_credits: el.pack_credits - el.remaining_credits,
                    user_name: getUserFullName(el),
                },
            }));
            if (data?.page?.total) {
                this.totalRecords = data.page.total;
            } else {
                this.totalRecords = 0;
            }
            // this.pageData = data?.page;
        },

        formatDateFromISO(dt, format = "EEE, MMM dd yyyy") {
            dt = dt.substr(0, 10);
            return DateTime.fromISO(dt).toFormat(format);
        },

        getFilterOptions(key) {
            // get unique filter options array
            return this.getFiltersData?.[key]?.filter((val) => val || val === 0) ?? [];
        },
        async updateColumnFilters() {
            this.pagination = {
                ...this.pagination,
                page: 1,
            };
            this.$refs["table"].reset();
            await this.fetchData();
        },
        async clearFilters() {
            this.filters = {};
            this.pagination = {
                limit: 10,
                page: 1,
            };
            this.$refs["table"].currentPerPage = 10;
            this.$refs?.["table"]?.reset();
            this.key++;
            await this.fetchData();
        },

        async onPageChange(params) {
            this.pagination = {
                ...this.pagination,
                page: params.currentPage,
            };
            await this.fetchData();
        },

        async onPerPageChange(params) {
            this.pagination = {
                ...this.pagination,
                limit: params.currentPerPage,
                page: 1,
            };
            await this.fetchData();
        },

        async onSortChange(params) {
            let sort_key = params[0].field;
            if (sort_key === "name") sort_key = "pack_name";
            else if (sort_key === "users") sort_key = "user_name";
            else if (sort_key === "created_at") sort_key = "credit_allocation_date";
            if (params[0].type == "none") {
                this.$refs["table"].sorts[0].type = "asc";
                this.pagination = {
                    ...this.pagination,
                    sort_on: sort_key,
                    sort_value: "asc",
                    page: 1,
                };
            } else {
                this.pagination = {
                    ...this.pagination,
                    sort_on: sort_key,
                    sort_value: params[0].type,
                    page: 1,
                };
            }
            await this.fetchData();
        },
    },
};
</script>
<style scoped lang="scss">
@import "@/mixins.scss";
@include tableComponent;
.loader-position {
    position: fixed;
    top: 43%;
    bottom: 0px;
    margin-left: 40%;
}
</style>
